
import { defineComponent } from "vue";
import katex from "katex";

export default defineComponent({
    props: { latex: { required: true, type: String } },
    setup(props) {
        const latexDisplay = katex.renderToString(props.latex);

        return { latexDisplay };
    },
});
